import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Helper from '../helper';
import { Spinner, Button, Table, Alert } from 'reactstrap';
import Database from '../services/database';
import { Link } from 'react-router-dom';

// Mock functions to replace the missing native modules
const AsyncStorage = {
  getItem: async (key) => {
    // Simulate getting data from storage
    return key === 'assignmentId' ? '59' : JSON.stringify({ status: 1 });
  }
};

// const Database = async (params) => {
//   return [
//     {
//       id: params.params.query.includes('Assignment') ? '12345' : null,
//       first_name: 'John',
//       last_name: 'Doe',
//       approved: true,
//       next_service_date: '2024-11-10',
//       quote: 150,
//     }
//   ];
// };

const AssignmentDetailPage = () => {
  const [loading, setLoading] = useState(true);
  const [assignment, setAssignment] = useState({});
  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [propertyId, setPropertyId] = useState('');
  const [accountType, setAccountType] = useState('');
  const [profile, setProfile] = useState({profile_img: 'https://via.placeholder.com/100', first_name: '', last_name: '' });
  const [routeComplete, setRouteComplete] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [task, setTask] = useState([]);
  const [selectedTask, setSelectedTask] = useState([]);
  const [newDescription, setNewDescription] = useState('');
  const [assignmentId, setAssignmentId] = useState(null);
  const [newQuote, setNewQuote] = useState(0);
  const [userType, setUserType] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const queryParams = new URLSearchParams(location.search);
      const assignmentId = queryParams.get('assignmentId');
      const accountType = queryParams.get('accountType');

      setAssignmentId(assignmentId);
      setUserType(accountType);
      // Fetch assignmentId and accountType from AsyncStorage
      if (!assignmentId) {
        assignmentId = '12345'; // Default assignment ID for testing
      }

      const query = `SELECT * FROM Assignment WHERE id = "${assignmentId}"`;
      const params = { Method: 'query', Module: 'Database', params: { query } };
      const assignmentResult = await Database(params);
      const routeCompleteStatus = JSON.parse(await AsyncStorage.getItem('RouteComplete'));
      const propertyId = queryParams.get('propertyId');
      setPropertyId(propertyId);
      let property = await getProperty(propertyId);
      let assignedEmployee = property.assigned_employee;
      var params2 = {
        Method: 'query',
        Module: 'Database',
        params: {
            query: `SELECT profile_img, first_name, last_name FROM Users WHERE id = ${assignedEmployee}`
        }
      };
      let technician = await Database(params2);
      setProfile(...technician);

      setAssignment(assignmentResult[0]);
      setRouteComplete(routeCompleteStatus.status === 1);
      setAccountType(JSON.parse(await AsyncStorage.getItem('AccountType')));

      // Fetch assignment details
      const assignmentDetail = await getAssignmentDetails(assignmentId);
      setAssignmentDetails(assignmentDetail);

      const task = await refreshDetails(assignmentId);
      setTask(task);

      // Fetch photos and videos
      const fetchedPhotos = await getPhotos(assignmentId);
      const fetchedVideos = await getVideos(assignmentId);

      setPhotos(fetchedPhotos);
      setVideos(fetchedVideos);
      setNewQuote(assignment.quote);
      setLoading(false);
      
    };

    fetchData();
  }, []);

  const goToServiceRequestList = () => {
    navigate(`/service-request-list?propertyId=${propertyId}&accountType=${accountType}`);
  }

  const refreshDetails = (id) => {
    return new Promise(async(resolve, reject) => {
        var query = 'SELECT *, a.id AS assignmentDetailId, a.detail_status AS isChecked, b.first_name, b.last_name FROM AssignmentDetail a  LEFT JOIN Assignment c ON a.assignment_id = c.id LEFT JOIN Properties d ON d.id = c.property_id  LEFT JOIN Users b ON d.assigned_employee = b.id  WHERE a.assignment_id = ' + id;
        var params = {
            Method: 'query',
            Module: 'Database',
            params: {
                query: query
            }
        };
        let result = await Database(params);
        resolve(result)
    });
};


  const addNewTask = async () => {
    setLoading(true);
    const user = await Helper.getUser();
    console.log("userrrr",user.value.id);
    
    const params = {
        Method: 'insert',
        Module: 'Database',
        params: {
          table: 'AssignmentDetail',
          obj: {
              description: newDescription,
              userId: user.value.id,
              assignment_id: assignmentId,
              status: 'Pending',
              detail_status: 0
          }
      }
    };

    try {
        const assignmentsData = await Database(params);
        // const assignmentDetail = await AssignmentDetail.get(assignmentsData);
        
        setTask(assignmentsData)
        const task = await refreshDetails(assignmentId);
        
        setTask(task);
        setNewDescription('');

    } catch (error) {
        console.error('Error fetching assignments:', error);
    } finally {
        setLoading(false);
    }
  };

  const getAssignmentDetails = async () => {
    const queryParams = new URLSearchParams(location.search);
    const propertyId = queryParams.get('propertyId');
    let property = await getProperty(propertyId);
    let assignedEmployee = property.assigned_employee;

    const query = `SELECT profile_img, first_name, last_name FROM Users WHERE id = ${assignedEmployee}`;
    const params = { Method: 'query', Module: 'Database', params: { query } };
    return await Database(params);
  };

  const getPhotos = async (assignmentId) => {
    const query = `SELECT * FROM Photos WHERE assignment_id = "${assignmentId}"`;
    const params = { Method: 'query', Module: 'Database', params: { query } };
    return await Database(params);
  };

  const getVideos = async (assignmentId) => {
    const query = `SELECT * FROM Videos WHERE assignment_id = "${assignmentId}"`;
    const params = { Method: 'query', Module: 'Database', params: { query } };
    return await Database(params);
  };

  const handleCheck = async (item, index) => {
    console.log("item", item);
    console.log("index", index);
  
    var list = [...task];  // Make a copy of the task list to avoid mutating state directly.
    var listItem = list[index];
  
    // Toggle the checked state of the item
    listItem.isChecked = !listItem.isChecked;
  
    setSelectedTask((prevTasks) => {
      let updatedTasks;
      // If the task is checked, add it to the selectedTasks list
      if (listItem.isChecked) {
        updatedTasks = [...prevTasks, listItem];
      } else {
        // If unchecked, remove the task from the list
        updatedTasks = prevTasks.filter(task => task.assignmentDetailId !== listItem.assignmentDetailId);
      }
  
      // Log updated state within the state setter callback
      console.log("Updated Selected Tasks:", updatedTasks);
  
      return updatedTasks; // Return the updated list
    });
  
    var params = {
      Method: 'update',
      Module: 'Database',
      params: {
        table: 'AssignmentDetail',
        setAll: {
          detail_status: listItem.isChecked ? 1 : 0,
        },
        id: listItem.assignmentDetailId
      }
    };
  
    // Make the database call after updating the state
    await Database(params);
  };
  
  const removeSelected = async () => {
    setLoading(true);

    console.log(selectedTask);
    
    
    let newAssignmentDetails = [...selectedTask]; 
    // Copy current state to modify it
    let checkedItems = [];

    // Loop through selected items and perform delete operations
    for (let item of newAssignmentDetails) {
        if (item.isChecked) {
            checkedItems.push(item);
            console.log('deleting assignmentDetailId', item.assignmentDetailId);

            const params = {
                Method: 'delete',
                Module: 'Database',
                params: {
                    table: 'AssignmentDetail',
                    id: item.assignmentDetailId
                }
            };

            await Database(params);
        }
    }

    // Remove checked items from the assignment details array
    checkedItems.forEach((listItem) => {
        newAssignmentDetails = newAssignmentDetails.filter(
            (mainItem) => mainItem.assignmentDetailId !== listItem.assignmentDetailId
        );
    });

    console.log('checkedItems are', checkedItems.length);

    // Update the 'Assignment' table with approved status as 0
    let params = {
        Method: 'update',
        Module: 'Database',
        params: {
            id: assignment.id,
            table: 'Assignment',
            setAll: {
                approved: 0
            }
        }
    };

    await Database(params);

    // Update state to reflect changes
    setAssignmentDetails(newAssignmentDetails);

    // Reset the loading state
    setLoading(false);

    const task = await refreshDetails(assignmentId);
    setTask(task);
};
  

  // const handleCheck = async (item, index) => {
  //   var list = task;
  //   var listItem = list[index];
    
  //   listItem.isChecked = !listItem.isChecked;

  //   let selectedTasks = [];
    
  //   if(listItem.isChecked){
  //     selectedTasks.push(listItem);
  //   }
    
  //   setSelectedTask((prevTasks) => (
  //     [...prevTasks, ...selectedTasks]
  //   ));

  //   console.log(selectedTasks, selectedTask);

  //   var params = {
  //       Method: 'update',
  //       Module: 'Database',
  //       params: {
  //           table: 'AssignmentDetail',
  //           setAll: {
  //               detail_status: listItem.isChecked ? 1 : 0,
  //           },
  //           id: listItem.assignmentDetailId
  //       }
  //   };

  //   await Database(params);
    
  //   // this.setState({assignmentDetails: list}, async () => {
  //   //     var params = {
  //   //         Method: 'update',
  //   //         Module: 'Database',
  //   //         params: {
  //   //             table: 'AssignmentDetail',
  //   //             setAll: {
  //   //                 detail_status: listItem.isChecked ? 1 : 0,
  //   //             },
  //   //             id: listItem.assignmentDetailId
  //   //         }
  //   //     };

  //   //     await Database(params); 

  //   //     if (listItem.isChecked){
  //   //         let user = await Helper.getUserByPropertyId(this.state.propertyId);
  //   //         let message = `Your service provider has finished the task "${listItem.description}"`;
  //   //     }
  //   // })
  //   // this.isAssignmentComplete();
  // }

  const getProperty = async (propertyId) => {
    return new Promise (async (resolve,reject) => {
        var query = `SELECT * FROM Properties WHERE id = ${propertyId}`
            var params = {
                Method: 'query',
                Module: 'Database',
                params: {
                    query: query
                }
            };
            var result = await Database(params);
            // console.log('Selected property result', result);
            resolve(result ? result[0] : null);
    });
  }

  const handleBeginRoute = () => {
    setRouteComplete(true);
  };

  const handleChange = (e) => {
    setNewDescription(e.target.value);
  };

  const handleQuote = (e) => {
    setNewQuote(e.target.value);
  };

  const addQuote = async () => {
    const queryParams = new URLSearchParams(location.search);
    const assignmentId = queryParams.get('assignmentId');

    var params = {
        Method: 'update',
        Module: 'Database',
        params: {
            table: 'Assignment',
            setAll: {
                quote: newQuote
            },
            id:  assignmentId
        }
    };

    let assignmentDetailId = await Database(params);
    console.log(newQuote);
    console.log(assignment);
    
    setAssignment((prevQuote) => ({
      ...prevQuote,
      quote: newQuote || assignment.quote,
    }));

    setNewQuote(0);
};

//   const removeSelected = async (ref) => {
//     setLoading(true);
//     let newAssignmentDetails = this.state.assignmentDetails;
//     let checkedItems = [];
//     for (var item of newAssignmentDetails){
//         if (item.isChecked){
//             checkedItems.push(item);
//             console.log('deleting assignmentDetailId', item.assignmentDetailId);
//             var params = {
//                 Method: 'delete',
//                 Module: 'Database',
//                 params: {
//                     table: 'AssignmentDetail',
//                     id: item.assignmentDetailId
//                 }
//             };
    
//             await Database(params);
//         } 
//     }
//     checkedItems.forEach((listItem, listIndex) => {
//         newAssignmentDetails.forEach((mainItem, mainIndex) => {
//             if (listItem.assignmentDetailId == mainItem.assignmentDetailId){
//                 newAssignmentDetails.splice(mainIndex, 1);
//             }
//         });
//     });
//     console.log('checkedItems are', checkedItems.length);

//     params = {
//         Method: 'update',
//         Module: 'Database',
//         params: {
//             id: this.state.assignment.id,
//             table: 'Assignment',
//             setAll: {
//                 approved: 0
//             }
//         }
//     };

//     await Database(params);

//     let assignment = this.state.assignment;
//     assignment.approved = 0;

//     this.setState({assignmentDetails: newAssignmentDetails, assignment: assignment, loading: false});
//     // let message = `Assignment line item removed from assignment property ${property.addressLine1}, ${property.city}, ${property.state} ${property.zip} assignment ${this.state.assignment.id} and will now need approval`;
//     // send to admin
//     // await Helper.sendPushNotification(15, message);
//     // send to customer
//     // await Helper.sendPushNotification(this.state.assignment.user_id, message);
//     // send to assigned employee
//     // await Helper.sendPushNotification(property.assigned_employee, message);
// };


  return (
    <div className="py-10 bg-slate-50 min-h-dvh">
      <div className="w-full max-w-2xl m-auto bg-white rounded-lg shadow-md p-10">
        {loading ? (
          <div style={styles.loading}>Loading...</div>
        ) : (
          <div className="flex flex-col text-center">
            <Button color="primary" onClick={goToServiceRequestList} className="block w-fit py-1.5 px-4 bg-blue-600 text-sm text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500" style={{marginTop: '20px'}}>
                    Go Back
                </Button>
            <p className="text-lg text-slate-600">{task.length ? '' : 'No tasks found'}</p>
            <p className="text-lg text-slate-600">
              {accountType === 'Customer'
                ? `Technician: ${profile.first_name} ${profile.last_name}`
                : `Technician: ${profile.first_name} ${profile.last_name}`}
            </p>
            <p className="text-base text-slate-600">
              Status: {assignment.approved ? 'Approved' : 'Pending'}
            </p>
            <p className="text-base text-slate-600">
              Service Date: {assignment.next_service_date || 'TBD'}
            </p>

            <div className="mx-auto my-4">
              <img
                style={styles.profileImage}
                src={profile.profile_img}
                alt="Profile"
              />
            </div>
            <div className="flex flex-wrap items-center justify-center">
              <div style={styles.buttonWrapper}>
                <button className="bg-slate-500 px-6 py-2.5 text-base text-white font-medium tracking-widest rounded ml-2">
                  {assignment.quote ? `Current Quote: $${assignment.quote}` : 'Pending Quote'}
                </button>
              </div>

              {!routeComplete && assignmentDetails.length > 0 && accountType !== 'Customer' && (
                <div style={styles.buttonWrapper}>
                  <button className="bg-slate-500 px-6 py-2.5 text-base text-white font-medium tracking-widest rounded ml-2" onClick={handleBeginRoute}>
                    Begin Route
                  </button>
                </div>
              )}

              {/* {routeComplete && (
                <div style={styles.buttonWrapper}>
                  <button className="bg-slate-500 px-6 py-2.5 text-base text-white font-medium tracking-widest rounded ml-2">Route Complete</button>
                </div>
              )} */}
            </div>
            {/* <div>Account Role: {userType}</div> */}
            {
              userType == 1 || userType == 3 &&
              <div className="relative pr-32 mt-7">
              <input
                type="number"
                id="quote"
                placeholder="Enter Quote"
                value={newQuote}
                onChange={handleQuote}
                className="p-3 block w-full border border-gray-300 rounded-l focus:outline-none focus:ring focus:ring-blue-500"
              />
              <button className="absolute right-0 top-0 bg-slate-500 w-32 h-[50px] py-2 text-base text-white font-medium tracking-wide rounded-r ml-2" onClick={addQuote}>Save Quote</button>
            </div>
            }
              {
                task.length > 0 &&
                <ul className="mt-7">
                  {task && task.map && task.map((item, index) => (
                    <li key={item.assignmentDetailId} style={styles.listItem}>
                      <input
                        type="checkbox"
                        checked={item.isChecked || false}
                        onChange={() =>{handleCheck(item, index)}}
                        value={item.isChecked}
                        // onChange={() => handleCheck(item.assignmentDetailId)}
                      />
                      <span style={styles.listText}>{item.description}</span>
                      <Link to={`/upload?assignmentId=${assignmentId}&assignmentDetailId=${item.assignmentDetailId}&accountType=${userType}`} className="bg-white h-6 px-2.5 flex items-center justify-center text-xs text-slate-500 font-medium tracking-wide rounded-lg ml-auto cursor-pointer">Upload Details</Link>
                    </li>
                  ))}
                </ul>
              }

            <input
              type="text"
              id="name"
              placeholder="New Task Description"
              value={newDescription}
              onChange={handleChange}
              className="my-5 p-3 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
            />
            <div className="flex flex-wrap items-center justify-center pt-1">
                <button onClick={addNewTask} className="bg-[#97c83e] px-6 py-2.5 text-base text-white font-medium tracking-widest rounded">Add New Task</button>
                <button onClick={removeSelected} className="bg-[#97c83e] px-6 py-2.5 text-base text-white font-medium tracking-widest rounded ml-2">Remove Selected Tasks</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    textAlign: 'center',
  },
  content: {
    marginTop: 20,
    textAlign: 'center',
  },
  statusText: {
    fontSize: 20,
    fontWeight: '300',
    color: '#333',
  },
  imageContainer: {
    marginTop: 20,
  },
  profileImage: {
    width: 110,
    height: 110,
    borderRadius: '50%',
  },
  buttonWrapper: {
    marginTop: 10,
  },
  buttonText: {
    fontSize: 16,
    letterSpacing: 1,
    color: '#fff',
    fontWeight: '500',
    padding: '10px 20px',
    backgroundColor: '#97c83e',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 5,
  },
  button: {
    fontSize: 16,
    letterSpacing: 1,
    color: '#fff',
    fontWeight: '500',
    padding: '10px 20px',
    backgroundColor: '#97c83e',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 5,
    marginBottom: 10,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#97c83e',
    color: '#fff',
    marginBottom: 5,
    borderRadius: 3,
  },
  listText: {
    marginLeft: 10,
    fontWeight: '500',
    fontSize: 14,
  },
};

export default AssignmentDetailPage;
