import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import Helper from '../helper';
import Database from '../services/database';

const AddServiceRequestPage = () => {
    const [succssMessage, setSuccssMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        name: { required: true, validation: 'length', min: 5, value: '' },
        serviceDate: { required: true, validation: 'dateValid', value: moment(new Date()).format('MM/DD/YYYY') },
    });
    const [serviceRequest, setServiceRequest] = useState(null);
    const [propertyId, setPropertyId] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [serviceRequestId, setServiceRequestId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Parse query parameters from the URL
        const queryParams = new URLSearchParams(location.search);
        const propertyId = queryParams.get('propertyId');  
        console.log('propertyId is', propertyId);
        var accountType = queryParams.get('accountType');
        if (accountType == null) {
            accountType = JSON.parse(localStorage.getItem('AccountType'));
        }
        // Set state based on query params
        setPropertyId(propertyId);
        console.log('propertyId again is', propertyId);
        setAccountType(accountType);
        console.log('use effect call');
        async function loadData() {
            setLoading(true);
            const serviceRequest = null; // Assume serviceRequest data fetching logic here
            setServiceRequest(serviceRequest);
            setServiceRequestId(serviceRequest?.id);
            await getProperty(propertyId);
            await createServiceRequest(propertyId);
            console.log('created');
            navigate(`/service-request-list?propertyId=${propertyId}&accountType=${accountType}`)
            setLoading(false);
        }
        loadData();
    }, []);

    const handleSubmit = async (propertyId) => {
        return new Promise(async (resolve, reject) => {
            await createServiceRequest(propertyId);
            console.log('resolved');
            resolve();
        });
    };

    const getProperty = async (propertyId) => {
        return new Promise(async (resolve, reject) => {
            let query = `SELECT addressLine1 FROM Properties WHERE id = ${propertyId}`;
            var params = {
                Method: 'query',
                Module: 'Database',
                params: {
                    query: query
                }
            };

            let result = await Database(params);
            if (result && result[0]){
                form.name.value = result[0].addressLine1;
            }
            resolve();
        });
    }

    const createServiceRequest = async (propertyId) => {
        var self = this;
        return new Promise(async (resolve, reject) => {
            let userId = await Helper.getUserId();
            let assignedEmployeeQuery = `SELECT assigned_employee FROM Properties WHERE id = ${propertyId}`;

                    var params = {
                        Method: 'query',
                        Module: 'Database',
                        params: {
                            query: assignedEmployeeQuery
                        }
                    };

                    let assignedEmployee, employeeAssignments;
                    let assignedEmployeeList = await Database(params);
                    console.log('assignedEmployeeList is', assignedEmployeeList);
                    if (assignedEmployeeList && assignedEmployeeList[0]){
                        assignedEmployee = assignedEmployeeList[0].assigned_employee;
                    }

                    if (assignedEmployee){

                        var params = {
                            Method: 'insert',
                            Module: 'Database',
                            params: {
                                table: 'Assignment',
                                obj: {
                                    name: form.name.value,
                                    user_id: userId,
                                    next_service_date: serviceRequest?.next_service_date || '', 
                                    property_id: propertyId,
                                    job_status: 'Pending',

                                },
                            },
                        };
                
                        const assignmentId = await Database(params);

                        var params = {
                            Method: 'insert',
                            Module: 'Database',
                            params: {
                                table: 'ServiceRequest',
                                obj: {
                                    description: form.name.value,
                                    property_id: propertyId,
                                    user_id: userId,
                                    assignment_created: 1,
                                    assignment_id: assignmentId,
                                    next_service_date: moment(new Date()).format('MM/DD/YYYY')
                                }
                            }
                        };
                        
                        let serviceRequestid = await Database(params);

                        setSuccssMessage('Service request submitted');
                        console.log('created');
                        resolve();
                    } else {
                        console.log('failed');
                        setErrorMessage('No employee assigned to your selected property');
                        resolve();
                    }
        });
    }

    return (
        <div className="flex flex-col h-dvh items-center justify-center bg-gray-100">
            {loading ? (
                <div className="spinner-border animate-spin text-blue-600" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                <>
                <form className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                            Property Address
                        </label>
                        <input
                            type="text"
                            id="name"
                            placeholder="Enter Description"
                            value={form.name.value}
                            onChange={(e) => setForm({ ...form, name: { ...form.name, value: e.target.value } })}
                            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500" readOnly disabled
                        /> 
                    </div>

                    <button
                        type="button"
                        onClick={handleSubmit}
                        className={`w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500 ${form.name.value ? 'opacity-100 pointer-events-auto' : 'opacity-50 pointer-events-none'}`}
                    >
                        Create Service Request
                    </button>
                    
                    {succssMessage && (
                        <div className='col-span-2'>
                            <p className='text-green-600 text-center pt-4 font-semibold'>
                                {succssMessage}!
                            </p>
                        </div>
                    )}
                    {errorMessage && (
                        <div className='col-span-2'>
                            <p className='text-red-600 text-center pt-4 font-semibold'>
                                {errorMessage}!
                            </p>
                        </div>
                    )}
                </form>
                <Link className="max-w-[200px] mx-auto my-6 block text-center w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500" to={`/service-request-list?propertyId=${propertyId}&accountType=${accountType}`}>
                        Service Request List
                </Link>
                </>
            )}
        </div>
    );
};

export default AddServiceRequestPage;
